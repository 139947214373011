import {Input, InputCheckbox, InputCheckboxWithText, InputDataPicker, InputMaskPhone} from '../input';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, completeStep, setStep, uncompleteStep } from '../../redux/userStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { clearQuestion } from '../../redux/questionStore';
import { useCheckUserExistMutation } from '../../redux/API/formPacient';

const schema = yup
  .object({
    gender: yup.string().required('Виберіть стать'),
    share: yup.string().required('Виберіть один із варіантів'),
    firstname: yup.string().required('Поле не може бути пустим'),
    lastname: yup.string().required('Поле не може бути пустим'),
    surname: yup.string().required('Поле не може бути пустим'),
    birthday: yup.string().required('Поле не може бути пустим'),
    address: yup.string().required('Поле не може бути пустим'),
    phone: yup
      .string()
      .matches(
        '\\+38\\((050|063|066|067|068|093|095|096|097|098|099|073)\\) \\d{2} \\d{3} \\d{2}',
        'Введіть коректний номер',
      )
      .required('Поле не може бути пустим'),
    email: yup
      .string()
      .email('Не коректно введений email')
      .required('Поле не може бути пустим'),
  })
  .required();

export function FormUser() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
      getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [userExist, setUserExist] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, steps } = useSelector((state) => state.user);
  const [check] = useCheckUserExistMutation();

  const sendForm = (data) => {
    if (user.gender !== data.gender) {
      dispatch(clearQuestion());
      dispatch(completeStep('2'));
      dispatch(setStep(3));
      dispatch(uncompleteStep('3'));
    }

    if (Object.entries(errors).length === 0 && !steps.s_2) {
      dispatch(completeStep('2'));
      dispatch(setStep(3));
    }
    if (Object.entries(errors).length === 0) {
      dispatch(addUser(data));
      navigate('/reg/3');
    }
  };

  const onSubmit = async (data) => {
    const user = await check(data);

    if (user.error?.data.code == 'US02') {
      setUserExist(false);
      sendForm(data);
    }

    setUserExist(true);
  };

  const onSubmitIfExist = (data) => {
    sendForm(data);
  };

  useEffect(() => {
    if (user.firstname) {
      reset(user);
    }
  }, [user]);

  return (
    <div className="nk-block nk-block-lg">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="title nk-block-title">Заповніть форму</h4>
          <div className="nk-block-des">
            <p>
              Ми прагнемо забезпечити вам не лише якісне, а й індивідуальне лікування для досягнення
              оптимального стану вашого здоров'я. Ми цінуємо Ваш час і саме тому ми пропонуємо
              можливість заповнення первинної документації онлайн.
            </p>
          </div>
        </div>
      </div>
      <div className="card card-bordered card-preview">
        <div className="card-inner">
          <form
            className="form-validate"
            onSubmit={handleSubmit(userExist ? onSubmitIfExist : onSubmit)}>
            <div className="preview-block">
              <span className="preview-title-lg overline-title">Загальна інформація</span>
              <div className="row gy-4">
                <InputCheckbox
                  size={12}
                  title={'Стать'}
                  name={'gender'}
                  data={[
                    { id: 'man', title: 'Чоловік' },
                    { id: 'woman', title: 'Жінка' },
                  ]}
                  control={control}
                  register={register}
                  err={errors}
                />
                <Input
                  size={4}
                  title="Прізвище"
                  placeholder="Введіть своє прізвище"
                  icon="ni-user"
                  name="firstname"
                  autocomplete="firstName"
                  control={control}
                  register={register}
                  required={true}
                  err={errors}
                />
                <Input
                  size={4}
                  title="Ім'я"
                  placeholder="Введіть своє ім'я"
                  icon="ni-user"
                  name="lastname"
                  autocomplete="lastName"
                  control={control}
                  register={register}
                  required={true}
                  err={errors}
                />
                <Input
                  size={4}
                  title="По-батькові"
                  placeholder="Введіть своє ім'я по-батькові"
                  icon="ni-user"
                  name="surname"
                  control={control}
                  register={register}
                  required={true}
                  err={errors}
                />
                <InputDataPicker
                  size={4}
                  title="Дата народження"
                  icon="ni-calendar"
                  name="birthday"
                  control={control}
                  err={errors}
                  setValue={setValue}
                  register={register}
                  required
                />
                <Input
                  size={8}
                  title="Адреса проживання"
                  icon="ni-location"
                  placeholder="Введіть свою актуальну адресу проживання"
                  name="address"
                  control={control}
                  register={register}
                  required={true}
                  err={errors}
                />
              </div>
            </div>

            <hr className="preview-hr" />

            <div className="preview-block">
              <span className="preview-title-lg overline-title">Контактна інформація</span>
              <div className="row gy-4">
                <InputMaskPhone
                    name={'phone'}
                    title={'Телефон'}
                    icon="ni-mobile"
                    size={4}
                    control={control}
                    err={errors}
                />
                <Input
                    size={4}
                    title="Email"
                    placeholder="Введіть свій email"
                    icon="ni-mail"
                    name="email"
                    autocomplete={'email'}
                    control={control}
                    register={register}
                    err={errors}
                />

              </div>
            </div>

            <hr className="preview-hr" />

            <div className="preview-block">
              <span className="preview-title-lg overline-title">Як Ви дізнались про нашу клініку</span>
              <div className="row gy-4">
                <InputCheckboxWithText
                    size={12}
                    title={''}
                    name={'share'}
                    data={[
                      { id: 'instagram', title: 'Instagram/Facebook', textField: false },
                      { id: 'google', title: 'Google', textField: false },
                      { id: 'friend', title: 'Порекомендували друзі/знайомі/родичі', textField: true },
                      { id: 'other', title: 'Ваш варіант', textField: true }
                    ]}
                    control={control}
                    register={register}
                    err={errors}
                    getValue={getValues}
                    setValue={setValue}
                />

                <div className="form-group">
                  {userExist ? (
                      <button type="submit" className="btn btn-lg btn-primary">
                        Я підтверджую, і хочу добавити ще одну анкету
                      </button>
                  ) : (
                      <button type="submit" className="btn btn-lg btn-primary">
                        Наступний Крок
                      </button>
                  )}
                </div>
              </div>
            </div>

            <div className="alert alert-light alert-icon mt-5">
              <em className="icon ni ni-info"></em>Будь ласка, заповніть наведену нижче форму
              особистих даних з уважністю та точністю.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
