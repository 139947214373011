import { createSlice } from '@reduxjs/toolkit';

function setData(state, action){
    let data = action.payload

    if (typeof action.payload == 'string')
    {
        data = JSON.parse(action.payload)
    }

    if (data){
        state.user.firstname = data.firstname
        state.user.lastname = data.lastname
        state.user.surname = data.surname
        state.user.address = data.address
        state.user.birthday = data.birthday
        state.user.gender = data.gender
        state.user.phone =data.phone
        state.user.email = data.email
        state.user.share = data['share']
        state.user['share-value'] = data['share-value']
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState: {
        step : 1,
        complete: false,
        mobileMenu: false,

        steps: {
          s_1: false,
          s_2: false,
          s_3: false,
          s_4: false,
          s_5: false
        },

        confirmAgree: {
            confirm1: true,
            confirm2 : true,
            confirm3 : true,
            confirm4 : true
        },

        user : {
            firstname: '',
            lastname: '',
            surname: '',
            address: '',
            birthday: '',
            gender: '',
            phone: '',
            email: '',
            share: '',
            'share-value': ''
        }
    },
    reducers: {
        firstStepTrue(state) {
            if (state.step > 1) {
                for (const [key] of Object.entries(state.confirmAgree)) {
                    state.confirmAgree[key] = true
                }
            }
        },

        preStep(state) {
            if (state.step > 1) {
                state.step -= 1;
            }
        },

        setStep(state, action) {
            const st = typeof action.payload == 'number' ? +action.payload : state.step
            sessionStorage.setItem('actual_step', st)
            state.step = st
        },

        nextStep(state) {
            if (state.step < 5) {
                state.step = state.step+1;
            }

            sessionStorage.removeItem('actual_step')
            sessionStorage.setItem('actual_step', state.step)
        },

        changeConfirm(state, action) {
            state.confirmAgree[`confirm${action.payload}`] = !state.confirmAgree[`confirm${action.payload}`]
        },

        completeStep(state, action) {
            state.steps[`s_${action.payload}`] = true
        },

        uncompleteStep(state, action) {
            state.steps[`s_${action.payload}`] = false
        },

        addUser(state, action) {
            setData(state, action)
            sessionStorage.setItem('user', JSON.stringify(state.user));
            console.log(JSON.stringify(state.user))
        },

        configCompleteStep(state) {
            state.steps = Object.entries(state.steps).reduce((acc, [key, value]) => {
                acc[key] = Number(key.split('_')[1]) <= state.step-1;
                return acc;
            }, {})
        },

        configActualStep(state) {
            const act_step = sessionStorage.getItem("actual_step")
            if (act_step >= 1 && act_step <= 5) {
                state.step = +act_step
            }
        },

        configUserFromStorage(state) {
            if (sessionStorage.getItem("user")) {
                setData(state, {payload: sessionStorage.getItem("user")})
            }
        },

        setComplete(state, action) {
            state.complete = action.payload
            sessionStorage.setItem('complete',"true")
        },

        completeRegistration(state) {
            sessionStorage.clear()
            state.user = Object.fromEntries(Object.keys(state.user).map(key => [key, ""]))
            state.step = 1
            state.steps = Object.fromEntries(Object.keys(state.steps).map(key => [key, false]))
        },

        setMobileMenu(state, action){
            state.mobileMenu = action.payload
        }
    }
}
)

export const {addUser, configUserFromStorage, preStep, setMobileMenu, nextStep, completeStep, changeConfirm, firstStepTrue, configActualStep, configCompleteStep, setStep, uncompleteStep, setComplete, completeRegistration} = userSlice.actions;

export default userSlice.reducer;