import {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {PatternFormat} from "react-number-format";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import login from "../Pages/Auth/Login";

export function Input ({name, icon, placeholder = '',size,title, type='text', autocomplete, control, required = false, value = '', err, options = {}}){
    return (
        <div className={"col-sm-"+size}>
            <div className="form-group"><label className="form-label" htmlFor={name}>{title} {required ? '*' : ''}</label>
                <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                        <em className={"icon ni "+ icon}></em>
                    </div>
                    <Controller
                        control={control}
                        defaultValue=""
                        name={name}
                        render={({ field }) => (
                            <input id={name}
                                   type={type}
                                   className="form-control"
                                   placeholder={placeholder}
                                   autoComplete={autocomplete}
                                   {...field}
                                   onBlur={(e) => field.onChange(e.target.value.replace(/ +/g, ' ').trim())}
                            />
                        )}
                    />


                    {err && <span id="fv-full-name-error" className="invalid">{err[name]?.message}</span>}
                </div>
            </div>
        </div>
    )
}

export function InputDataPicker ({name, icon, size, title, required = false, err, minDate='1900-01-01', control, setValue}){

    const changeDateHandler = (e) =>{
        if (moment(e.target.value).isBefore(minDate)){
            setValue(name,minDate)
        } else
        if (moment(e.target.value).isAfter(moment().format('YYYY-MM-DD'))){
            setValue(name,moment().format('YYYY-MM-DD'))
        }
        else {
            setValue(name,e.target.value)
        }
    }

    return (
        <div className={"col-sm-"+size}>
            <div className="form-group"><label className="form-label" htmlFor={name}>{title} {required ? '*' : ''}</label>
                <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                        <em className={"icon ni "+ icon}></em>
                    </div>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <input type="date" name={name} className="form-control" onChange={field.onChange} value={field.value} disabled={field.disabled} ref={field.ref} placeholder={'Введіть дату народження'} min="1900-01-01" onBlur={(e)=> changeDateHandler(e)}/>
                        )}
                    />

                    {err && <span id="fv-full-name-error" className="invalid">{err[name]?.message}</span>}
                </div>
            </div>
        </div>
    )
}

export function InputCheckbox ({name, size, title, data, register, required, err}){

        return (
            <div className={"col-sm-"+size}>
                <div className="form-group">
                    <label className="form-label">{title}</label>
                    <div className="form-control-wrap">
                        <ul className="custom-control-group">
                            {
                                data.map((e)=>{
                                    return(
                                        <li key={e.id}>
                                            <div className="custom-control custom-radio custom-control-pro no-control">
                                                <input type="radio" className="custom-control-input" name={name} id={e.id} value={e.title} {...register(name, {required: {required}})} />
                                                <label className="custom-control-label" htmlFor={e.id}>{e.title}</label>
                                                {err.gender && e.id === 'man' ? <span id='man' className="invalid">{err[name]?.message}</span> : ''}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
}
export function InputMaskPhone ({name, size, title, icon, control, err}){

    return (
        <div className={"col-sm-"+size}>
            <div className="form-group"><label className="form-label" htmlFor={name}>{title}</label>
                <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                        <em className={"icon ni "+ icon}></em>
                    </div>

                    <Controller
                        name={name}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <PatternFormat className="form-control" format="+38(0##) ## ### ##" allowEmptyFormatting mask="_" value={field.value} onChange={field.onChange}/>
                        )}
                    />
                    {err && <span id="fv-full-name-error" className="invalid">{err[name]?.message}</span>}
                </div>
            </div>
        </div>
    )
}

export function InputCheckboxSurveyStatus ({name, size, title, register, buttons = [{id:'yes', title: 'Так'}, {id:'no', title: 'Ні'}], required, inputFieldStatus, inputFieldPlaceholder, onlyInput, val, err, setValue}){

    const [inputStatus, setInputStatus] = useState(false)
    const [access, setAccess] = useState(false)

    const titleToUpper = () =>{
        return title[0].toUpperCase() + title.slice(1)
    }

    useEffect(()=>{
        if (inputFieldStatus && val){
            if(val === 'Так') {
                setInputStatus(true)
            }
            else {
                setInputStatus(false)
            }
        }

    },[val])

    useEffect(()=>{
        inputFieldStatus & inputStatus ? setAccess(true) :  setAccess(false)

    },[inputStatus])


    return (
        <div className={"min-col-80 col-sm-6 col-md-"+size}>
            <div className="form-group">
                <label className="form-label">{titleToUpper()}</label>
                <div className="form-control-wrap">
                    {!onlyInput &&
                        <ul className="custom-control-group">
                        {
                            buttons.map((e)=>{
                                return <li key={e.id}>
                                    <div className="custom-control custom-radio custom-control-pro no-control">
                                        <input type="radio" className="custom-control-input" name={name} id={`${e.id}-${name}`} value={e.title} {...register(name, {required: {required}})} onClick={()=> {
                                            if (e.id === 'yes'){
                                                setInputStatus(true)
                                            } else
                                            {
                                                setInputStatus(false)
                                                inputFieldStatus && setValue(`${name}-value`, '')
                                            }
                                        }
                                        }/>
                                        <label className="custom-control-label" htmlFor={`${e.id}-${name}`}>{e.title}</label>
                                    </div>
                                </li>
                            })
                        }

                    </ul>


                    }
                    {onlyInput  ? <input type="text" className="form-control mt-3" id={name} placeholder={inputFieldPlaceholder} {...register(`${name}-value`, {required: {required}})} /> :
                        access  && <input type="text" className="form-control mt-3" id={name} placeholder={inputFieldPlaceholder} {...register(`${name}-value`, {required: {required}})}  />}
                </div>
                {err[name] && <span id={`yes-${name}-error`} style={{color: "#e85347", fontSize: "11px", fontStyle: "italic"}}>{err[name]?.message}</span>}
            </div>
        </div>
    )
}

export function InputCheckboxWithText ({name, size, title, data, register, required, err, setValue, getValue}){

    const [textFieldStatus, setTextFieldStatus] = useState(false)
    const [active, setActive] = useState(null)
    useEffect(()=>{
        if(getValue()[`${name}-value`]?.length){
            setTextFieldStatus(true)
        }
    })

    useEffect(()=>{
        if(!textFieldStatus){
            setValue(`${name}-value`, null)
            setTextFieldStatus(false)
        }
    },[active])

    return (
        <div className={"col-sm-"+size}>
            <div className="form-group">
                <label className="form-label">{title}</label>
                <div className="form-control-wrap">
                    <ul className="custom-control-group">
                        {
                            data.map((e)=>{
                                return(
                                    <li key={e.id}>
                                        <div className="custom-control custom-radio custom-control-pro no-control">
                                            <input type="radio" className="custom-control-input" name={name} id={e.id} value={e.title} {...register(`${name}`, {required: {required}})} onChange={()=>{
                                                setActive(e.id)
                                                setTextFieldStatus(!!e.textField)
                                            }} />
                                            <label className="custom-control-label" htmlFor={e.id}>{e.title}</label>
                                            {err['share'] && e.id === 'instagram' ? <span id='instagram' className="invalid">{err[name]?.message}</span> : ''}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {textFieldStatus && <input type="text" className="form-control mt-3" name={`${name}-value`} placeholder='Введіть детальнішу інформацію' {...register(`${name}-value`, {required: {required}})} />}
                </div>
            </div>
        </div>
    )
}

